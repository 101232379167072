<template>
    
    <div>
        <b-row>
            <b-col
                md="6"
                sm="12"
            >
                <statistic-card-horizontal
                    icon="UserIcon"
                    color="info"
                    :statistic="providerH"
                    statistic-title="Proveedor"
                />
            </b-col>
            <b-col
                md="6"
                sm="12"
            >
                <statistic-card-horizontal
                    icon="TargetIcon"
                    color="info"
                    :statistic="scoreH + ' %'"
                    statistic-title="Porcentaje obtenido"
                />
            </b-col>
        </b-row>
                
        <b-row>
            <b-col>
                <b-form>
                         
                    <table class="content-table table table-bordered text-center w-100">
                        <thead>
                            <tr>
                                <th colspan="12">CUMPLIMIENTO MENSUAL DE LAS OBLIGACIONES LABORALES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><label>11</label></td>
                                <td colspan="3">
                                    <label class="form_question">Examen Médico Ocupacional de Salida (EMOS)</label>
                                </td>
                                <td>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="question__11__yes" v-model="questionsArr[10]" class="custom-control-input" value="1" disabled>
                                        <label class="custom-control-label" for="question__11__yes">SÍ</label>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="question__11__parcial" v-model="questionsArr[10]" class="custom-control-input" value="2" disabled>
                                        <label class="custom-control-label" for="question__11__parcial">PARCIAL</label>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="question__11__not" v-model="questionsArr[10]" class="custom-control-input" value="3" disabled>
                                        <label class="custom-control-label" for="question__11__not">NO</label>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="question__11__na" v-model="questionsArr[10]" class="custom-control-input" value="4" disabled>
                                        <label class="custom-control-label" for="question__11__na">N.A.</label>
                                    </div>
                                </td>
                                <td colspan="3">
                                    <div>
                                        <ButtonUpload :code="codeArr[10]"/> 
                                    </div>                        
                                </td>
                                <td colspan="1">
                                    <div>
                                        <ButtonComment :comment="medisaludComment" :medisalud="true" :question="codeArr[10]" @update-comment="updateComment($event)"/>
                                    </div>                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                
                </b-form>    
            </b-col>            
        </b-row>
        
    </div>
    
</template>

<script>

    import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import ButtonComment from '@core/components/button-comment/ButtonComment.vue'
    import ButtonUpload from '@core/components/button-upload/ButtonUpload.vue'
    import router from '@/router'
    import axios from '@axios'

    export default {
        components: {
            StatisticCardHorizontal,
            ToastificationContent,
            ButtonComment,
            ButtonUpload
        },
        data (){
            return {
                medisaludComment: '',
                providerH: '',
                scoreH: 0,

                questionsArr: [
                    null, null, null, null, null, null, null,  
                    null, null, null, null, null, null, null,    
                ],            
                codeArr: [
                    null, null, null, null, null, null, null,  
                    null, null, null, null, null, null, null, 
                ]
            }
        },
        created(){
            this.getDataHeader()
        },
        async beforeMount(){
            this.getAnswersInformation();
            this.getMedisaludComment();
            await this.getFileQuestion();
        },
        methods: {
            async getFileQuestion () {
                const response = await axios.get('/form/questions');
                this.codeArr = response.data;			
            },
            getAnswersInformation () {
                axios.get(`/reviser/form-a/${router.currentRoute.params.id}`)
                .then(response => {
                    if(response.data){
                        let cont = 0  
                        for (const key in response.data) 
                        {                                         
                            if(key === 'question__'+(cont+1)) 
                            {
                                this.questionsArr[cont] = response.data[key]   
                                cont++
                            }
                        } 
                    } else {                    
                        for (let i = 0; i < this.codeArr.length; i++) 
                        {
                            this.questionsArr[i] = ''   
                        }
                    }        
                })
                
            },
            getMedisaludComment () {
                axios.get(`/medisalud/form/${router.currentRoute.params.id}/comment`)
                .then(response => this.medisaludComment = response.data.comment)
            },
            async updateComment (data) {
                try {

                    this.medisaludComment = data.comment

                    const payload = {
                        medisalud_comments: data.comment
                    }

                    const response = await axios.post(`/medisalud/form/${router.currentRoute.params.id}/comment/update`, payload)

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    });

                } catch (error) {

                    const message = error.response.data.message ? error.response.data.message : 'Error al actualizar los comentarios.';

                    this.$toast({
                        component: ToastificationContent,
						props: {
							title: message,
							icon: 'AlertTriangleIcon',
							variant: 'danger'
						}
                    });

                }
            },
            getDataHeader () {
                axios.get(`/reviser-form/${router.currentRoute.params.id}`)
                .then(response => {
                    this.scoreH = response.data.score
                    this.providerH = response.data.provider.user.names
                })
            }
        }
    }

</script>

<style scoped>

    .content-table {
        border-collapse: collapse;
        margin: 0 0 25px 0;
        min-width: 400px;
        border-radius: 10px 10px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    .content-table thead tr th {
        font-weight: bold;
        font-size: 20px;
        padding: 45px 0px;
    }

    .content-table td {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .form_question{
        font-size: 1rem;
        margin-bottom: 0px;
    }
    
    .custom-control-label{
        font-size: .8rem;
    }
    
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>